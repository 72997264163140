.modal {
  max-width: 600px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }
}
