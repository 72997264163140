.list {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .list {
    grid-template-columns: 1fr;
  }
}
