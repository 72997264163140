.row {
  display: flex;
  align-items: flex-start;
  padding-top: 5px;
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .row {
    width: 100%;
  }
}
