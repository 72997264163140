.card {
  display: flex;
  padding: 24px;
  min-height: 125px;
  border-radius: 24px;
  border: #e7e8ea 1px solid;
  color: #817e7f;
  position: relative;
  text-decoration: none;

  &.primary {
    color: #2e292b;
    border-color: hsl(196, 64%, 60%);
    border-width: 2px;
  }

  &.add {
    align-items: center;
    justify-content: center;
    border-color: rgba(87, 183, 218, 0.5);
    border-style: dashed;
    color: #57b7da;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
  }

  &.invoice {
    border-color: #868a97;
    color: #868a97;
  }
}

.image {
  display: inline-block;
  vertical-align: top;
  width: 35px;
  margin-right: 24px;

  img,
  svg {
    width: 100%;
    height: auto;
    padding-top: 4px;
  }
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #64c975;
  color: #fff;
  border-radius: 11px;
  border: #fff 2px solid;
}

.info {
  flex: 1;

  p {
    margin: 4px 0 0 0;
  }
}

.type {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.actions {
  position: absolute;
  top: 10px;
  right: 15px;
}
