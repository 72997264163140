.wrapper {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}

.graystyle {
  margin: 15px 0;

  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.divider {
  width: 100%;
  border-bottom: #d3d5d9 1px dashed;
  margin: 15px 0;
}
