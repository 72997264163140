.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  flex: none;
  margin-bottom: 0;
}

.switch {
  margin-left: 8px;
}

.number {
  display: inline-block;
  font-weight: 500;
  margin-left: 8px;
}
