.datepicker {
  display: flex;
  position: relative;
  transition-duration: 0.3s;
  border: #f7f7f7 2px solid;
  border-radius: 12px;

  &.widthAuto {
    min-width: 320px;
  }

  &.widthFull {
    width: 100%;
  }

  input {
    &,
    &:focus {
      border-color: transparent;
    }

    &:focus {
      border-color: #56b8da;
      z-index: 1;
    }
  }

  & > div {
    margin-bottom: 0;

    &:first-child {
      input:not(:focus) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-color: transparent;
      }
    }

    &:last-child {
      input:not(:focus) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-color: transparent;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .datepicker {
    width: 100%;
    border: 2px solid #56b8da;
    border-radius: 8px;

    input {
      &:focus {
        border-color: transparent;
      }
    }
  }
}
