.modal {
  max-width: 600px;
}

.item {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}

.picture {
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
}

.badge {
  background-color: #29cd6b;
  color: #fff;
  font-size: 10px;
  padding: 6px 9px;
  position: absolute;
  top: -2px;
  left: -2px;
  font-weight: 500;
  border-radius: 12px 0 12px 0;
}

.description {
  flex: 1;
  padding: 0 16px;
  margin-top: 2px;
}

.line {
  font-size: 13px;
  margin-bottom: 10px;
}

.text {
  color: #6c707a;
}

@media screen and (max-width: 640px) {
  .picture {
    padding: 10px 0;
  }

  .codeImg {
    height: 100%;

    img {
      height: 100%;
    }
  }
}
