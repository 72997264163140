.item {
  padding: 16px 0 16px 28px;
  box-shadow: inset 0 -1px 0 #e7e8ea;
  position: relative;
  cursor: pointer;

  &:last-child {
    box-shadow: none;
  }
}

.status {
  position: absolute;
  left: 0;
  top: 20px;
}

.header {
  display: flex;
  color: #2e292b;
  font-size: 16px;
  justify-content: space-between;

  &.highlighted {
    color: #ff5151;
  }
}

.type {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  padding-left: 12px;

  img {
    margin-left: 2px;
  }
}

.footer {
  color: #6c707a;
  font-size: 12px;
  display: flex;
}
