.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.stock {
  &:after {
    width: 300px;
    white-space: pre-line;
  }

  strong:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 6px;
    height: 6px;
    background-color: #fe8257;
    border-radius: 6px;
    margin: 6px 4px 0 -5px;
  }
}

.picture {
  margin-right: 8px;
}

.markers {
  display: inline-flex;
  margin-left: 5px;
}

@media screen and (max-width: 1024px) {
  .products {
    white-space: normal;

    thead {
      display: none;
    }

    tbody {
      td {
        padding-left: 0;
        padding-right: 0;

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
}
