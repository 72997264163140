.category {
  cursor: pointer;
  display: flex;
  color: #2e292b;
  font-weight: 500;
  line-height: 20px;
  border: #56b8da 2px solid;
  border-radius: 12px;
  padding: 10px;
  transition: all 200ms ease;

  &:hover {
    background-color: #56b8da;
    color: #fff;
  }
}

@media screen and (max-width: 1024px) {
  .category {
    font-size: 13px;
  }
}
