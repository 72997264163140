.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 190px;
  position: relative;
  padding: 0 0 20px 0;
  border-bottom: #57b7da 2px solid;
}

.container {
  display: flex;
  margin-bottom: 20px;
}

.info {
  margin-left: 10px;
}
