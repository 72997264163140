.scrollbar {
  padding-top: 16px;
  height: 100%;
  overflow: auto;

  &.scrollable {
    padding-bottom: 15px;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 15px;
      background: linear-gradient(0deg, rgba(37, 35, 36, 1) 0%, rgba(2, 0, 36, 0) 100%);
      border-bottom: 1px solid #303030;
    }
  }
}
