.grid {
  align-items: flex-end;
}

.input {
  margin-bottom: 0;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
