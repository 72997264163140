.uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.uploaderField {
  flex: unset;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }
}
