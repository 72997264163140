.balanceWrapper {
  font-weight: 500;
  text-align: right;
}

.balance {
  display: inline-block;
  vertical-align: top;
  font-size: 35px;
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  padding: 10px 20px;
}
