.container {
  display: flex;
}

.left {
  flex: 0;
  width: 190px;
  margin-right: 20px;
}

.right {
  flex: 1;
}

.group {
  margin-bottom: 0;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 40px 0 0 0;

  button {
    margin-left: 16px;
  }
}

@media screen and (max-width: 769px) {
  .container {
    flex-wrap: wrap;
  }

  .right {
    width: 100%;
  }

  .left {
    margin: 0 0 20px;
    flex: 0 1 100%;
    justify-content: center;
    text-align: center;

    > div {
      display: inline-block;
      vertical-align: top;
      // overflow: hidden;

      input {
        width: 100%;
      }
    }
  }
}
