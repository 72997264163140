.option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3px;
}

.label {
  font-size: 13px;
  line-height: 12px;
  font-weight: 400;
}

.description {
  font-size: 10px;
}
