.support {
  display: flex;
  align-items: center;
}

.chat {
  margin-right: 20px;
  border-right: #e0f0f7 1px solid;
}

@media screen and (max-width: 1024px) {
  .chat {
    margin-right: 10px;
    padding: 0 16px 0 0;
    width: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .phone,
  .chatText {
    display: none;
  }
}
