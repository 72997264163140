.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  float: right;
  height: 18px;
  margin-top: 6px;
}
