@use 'sass:math';

$card-per-row: 3;
$card-row-gap: 25px;

.list {
  flex-wrap: wrap;
  gap: $card-row-gap;
}

.card {
  width: calc(#{math.div(100%, $card-per-row)} - #{$card-row-gap});
}

.description {
  margin-top: 30px;
  color: #2e292b;

  p {
    margin-bottom: 20px;
  }
}

.actions {
  color: #c2c4cb;
  font-size: 26px;
  line-height: 14px;
  cursor: pointer;
  letter-spacing: -0.5px;
}

@media screen and (max-width: 1024px) {
  .card {
    width: calc(50% - #{$card-row-gap});
  }
}

@media screen and (max-width: 640px) {
  .card {
    width: 100%;
  }
}
