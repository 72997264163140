@import '../variables';

.container {
  position: relative;
}

.input {
  border: $borders-width solid $borders-color;
  font-size: inherit;
  font-family: inherit;
  border-radius: $borders-radius;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  padding: 0 32px 0 16px;
  color: inherit;
  transition-duration: $transition-duration-short;

  &:disabled {
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    color: #868a97;
  }

  &:focus {
    outline: 0;
    border-color: $primary-color;
  }
}

.inputOpen {
  border-radius: $borders-radius $borders-radius 0 0;

  &:focus {
    outline: 0;
    border-color: $borders-color;
  }
}

.suggestionsContainer {
  background-color: #fff;
  max-height: 240px;
  overflow-y: scroll;
  position: absolute;
  top: 38px;
  z-index: 100;
}

.suggestionsContainerOpen {
  border-radius: 0 0 $borders-radius $borders-radius;
  border: $borders-width solid $borders-color;
  font-size: inherit;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  padding: 0 8px;
  color: inherit;
}

.suggestionsList {
  margin: 0;
  padding: 0;
}

.suggestion {
  cursor: pointer;
  list-style: none;
  padding: 8px;
  font-size: 12px;
}
