.state {
  display: flex;
}

.name {
  font-weight: 500;
  margin-left: 15px;
}

.rows {
  display: flex;
  margin-left: 10px;
}

.counter {
  color: #868a97;
  font-size: 12px;
  text-align: center;

  strong {
    display: block;
    color: #322d2f;
  }
}
