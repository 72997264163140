.bot {
  display: flex;
  flex: 0.5 1;
  padding: 15px 10px;
  border-radius: 12px;
  color: #000;
  font-weight: 500;
  text-decoration: none;
  border: #56b8da 2px solid;

  &:hover {
    text-decoration: underline;
  }
}
