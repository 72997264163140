.paymentMethod {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.info {
  margin-right: 6px;
  font-size: 12px;
}
