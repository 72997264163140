.status {
  border-radius: 100%;
  text-align: center;
  min-width: 20px;
  height: 20px;
  line-height: 22px;

  &.grey {
    background-color: #2a9fd8;
  }

  &.cyan {
    background-color: #2a9fd8;
  }

  &.green {
    background-color: #2acd6a;
  }

  &.red {
    background-color: #e55249;
  }
}
