.wrapper {
  display: flex;
  min-height: 100%;
  width: 100%;
  background-color: #fff;

  &:after {
    display: flex;
    content: '';
    width: 45.6%;
    flex: 1;
    background: #fff url(~assets/bg.jpg) center right no-repeat;
    background-size: cover;
    z-index: 0;
  }
}

.container {
  display: flex;
  flex: 1;
  padding: 25px 20px;
  position: relative;
  z-index: 1;
  margin: 0 -110px 0 0;
  background-color: #fff;
  border-radius: 0 0 110px 0;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  margin-bottom: 65px;
}

.footer {
  align-self: center;
  max-width: 350px;
  font-size: 11px;
  color: #000;
  text-align: center;

  a {
    color: #56b8da;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    &:after {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .container {
    border-radius: 0 0 80px 0;
    margin: 0;
  }
}
