.content {
  padding-left: 100px;
  padding-right: 100px;
}

@media screen and (max-width: 640px) {
  .content {
    padding: 0;
  }
}
