.footer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 500;

  &Title {
    margin-right: 10px;
  }

  &Amount {
    font-size: 22px;
  }
}

.discount {
  margin-bottom: 0;
}

.items {
  margin: 10px 0 10px 0;
}

.itemForm {
  max-width: 850px;
  max-height: none;
}

.row {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: rgba(134, 138, 151, 0.1) 1px dashed;
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
}

.field {
  margin-bottom: 0;
}

.info {
  display: flex;
  align-items: center;
}

.amount {
  font-weight: 500;
}

.delete {
  display: flex;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  line-height: 15px;
  border-radius: 8px;
  font-size: 24px;
  color: #fff;
  background: #ff5151;
  margin-top: 1px;
  cursor: pointer;
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  .row {
    flex-wrap: wrap;
  }

  .info {
    width: 100%;
  }

  .details {
    width: 100%;
    justify-content: space-between;
    padding-left: 15px;
    text-align: left;
  }

  .amount {
    text-align: right;
  }
}
