.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #29cd6b;
  color: #fff;
  border-radius: 100%;

  &.medium {
    width: 28px;
    height: 28px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.reversal {
    background: #e55249;
  }

  &.refund {
    background: #817e7f;
  }
}
