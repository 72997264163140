@keyframes loading {
  0% {
    background-position-x: -800px;
  }
}

.line {
  height: 19px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
  background-size: 800px 104px;
  animation: loading 1s linear infinite forwards;
  margin-bottom: 10px;
  border-radius: 6px;

  &.dark {
    background: linear-gradient(to right, #252324 0%, #2b2327 20%, #252324 40%, #252324 100%);
  }
}
