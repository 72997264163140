.avatar {
  color: #000;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.source {
  background-color: transparent !important;
}

.orange {
  background-color: #fbe7de;
}

.yellow {
  background-color: #faeed3;
}

.blue {
  background-color: #e0f1f7;
}

.cyan {
  background-color: #ddf2f2;
}

.grey {
  background-color: #e7e8ea;
}

.violet {
  background-color: #dedef6;
}

.green {
  background-color: #e2f5e8;
}

.round {
  border-radius: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizeS {
  border-radius: 6px;
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.sizeM {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.sizeL {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

.sizeXL {
  width: 112px;
  min-width: 112px;
  height: 112px;
}

.fullRadius {
  border-radius: 100%;
}

.border {
  border-radius: 6px;
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text {
  font-weight: 600;
  color: #fff;
}

.responsive {
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
