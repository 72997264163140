.autosuggest {
  position: relative;
}

.autosuggestStatus {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 1;

  img {
    width: 16px;
    height: auto;
  }
}

.loader {
  padding: 0;
}
