.categories {
  padding: 0;
  overflow: hidden;
  margin-bottom: 32px;
}

.questionsTitle {
  padding-left: 80px;
  padding-right: 80px;
}

.header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 200px;
}

.title {
  font-size: 30px;
  line-height: 36px;
}

@media screen and (max-width: 1024px) {
  .questionsTitle {
    padding-left: 0;
    padding-right: 0;
  }
}
