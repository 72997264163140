.form {
  padding: 0 20%;
}

.title {
  color: #2e292b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}

.description {
  color: #868a97;
  font-size: 12px;
}

.container {
  padding: 14px 0;
}

.collapseContent {
  padding-bottom: 0;
  margin-bottom: -10px;
}

.switch {
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .form {
    padding: 0;
  }
}
