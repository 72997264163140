@import 'components/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select {
  width: 240px;
  margin-bottom: 0;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fileUploader {
  width: 100%;
  margin: 20px 0;
}

.dropText {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px 60px;
}

.button {
  margin-top: 15px;
}

.download {
  width: 190px;
  height: 40px;
  min-height: $element-min-height;
  text-align: center;
  background-color: $accent-color;
  vertical-align: middle;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  font-size: 1rem;
  line-height: (20 / 14);

  color: #ffffff;
  border: 0 none;
  border-radius: 12px;
  padding: 0 16px;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    opacity: 0.8;
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px);
  }

  span {
    letter-spacing: 0.12px;
    font-weight: 500;
    padding: 6px 0;
  }
}

@media screen and (max-width: 769px) {
  .header {
    flex-direction: column;
  }

  .select {
    width: 100%;
    margin-bottom: 10px;
  }

  .buttonDownload {
    width: 100%;
  }
}
