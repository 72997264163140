.folder {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  position: relative;
  cursor: pointer;
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  justify-content: center;
}
