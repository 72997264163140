.table {
  font-size: 12px;
  border-spacing: 0;

  thead {
    td {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  tbody {
    td {
      padding: 5px;
    }
  }
}

.error {
  color: red;
  font-size: 11px;
}
