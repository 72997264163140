.collapse {
  &:last-child {
    .collapseHeader {
      border-bottom: none;
    }
  }
}

.collapseHeader {
  color: #6c707a;
  font-weight: 500;
  border-radius: 0;
  border-bottom: rgba(134, 138, 151, 0.1) 1px solid;
}

.collapseContent {
  color: #868a97;
  border-bottom: rgba(134, 138, 151, 0.1) 1px solid;
  white-space: pre-line;
}
