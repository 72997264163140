@use 'sass:math';
@import 'variables';

:root {
  --toastify-toast-width: 310px;
  --toastify-toast-min-height: 50px;
  --toastify-font-family: 'Rubik', sans-serif;
  --toastify-text-color-light: #000;
}

:global {
  * {
    box-sizing: border-box;

    outline: none;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  html,
  body {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: math.div(20, 14);
    background: $content-background;
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  html,
  body {
    height: 100%;
    min-height: 100%;
    position: relative;
  }

  .Toastify__toast {
    font-size: 12px;
    font-weight: 500;
    border-radius: 16px;
  }

  .Toastify__progress-bar {
    height: 2px;
  }

  .sc-chat-window {
    box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.17) !important;
  }

  .sc-powered-by {
    display: none !important;
  }

  .b24-widget-button-shadow,
  .b24-widget-button-wrapper {
    display: none !important;
  }

  .bx-livechat-wrapper {
    right: 25px !important;
    bottom: 25px !important;
  }

  .showAfterCssLoaded {
    display: none;
  }

  @media screen and (max-width: 640px) {
    .bx-livechat-wrapper {
      bottom: 0 !important;
    }
  }
}

button {
  font-family: 'Rubik', sans-serif;
}
