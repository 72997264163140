.plan {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  text-align: center;
  position: relative;

  &.active {
    border-color: #56b7d9;
  }
}

.badge {
  background-color: #29cd6b;
  color: #fff;
  font-size: 10px;
  padding: 6px 9px;
  position: absolute;
  top: -2px;
  left: -2px;
  font-weight: 500;
  border-radius: 12px 0 12px 0;
}

.row {
  padding: 13px;
  border-bottom: #f7f7f7 2px solid;
}

.title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.price {
  font-size: 20px;
  font-weight: 500;
}

.description {
  flex: 1;
  white-space: pre-line;
}

.actions {
  padding: 13px 24px;
}
