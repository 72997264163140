.item {
  border-bottom: #f7f7f7 2px solid;
  flex: 0 0 31%;
  min-height: 190px;
  position: relative;
  margin: 10px;
}

.info {
  display: flex;
  justify-content: space-between;
}

.description {
  width: 70%;
  padding: 0 16px;
  margin-top: 2px;
}

.line {
  font-size: 13px;
  margin-bottom: 10px;
}

.title {
  font-weight: 500;
}

.text {
  color: #6c707a;
}

.picture {
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.image {
  max-width: 100%;
  height: auto;
}

.buttonShow {
  margin: 20px 0;
}

.buttonDelete {
  position: absolute;
  top: 0;
  right: 0;
  min-height: auto;
}

@media screen and (max-width: 768px) {
  .item {
    flex: 0 0 100%;
    margin: 10px 0;

    button svg {
      width: 16px;
      height: 16px;
      max-width: 16px;
      max-height: 16px;
    }
  }
}
