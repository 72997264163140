$menu-background: rgb(45, 41, 43);
$menu-background-active: rgb(37, 35, 36);
$menu-color: #bfbebe;
$menu-color-active: #ffffff;
$menu-border-active: #6eb6d6;

$content-background: #fbfbfb;
$content-color: #2d292b;

$card-background: #ffffff;

$white-color: #ffffff;
$light-color: #6c707a;
$accent-color: #29cd6b;
$sub-accent-color: #e9fae6;
$primary-color: #56b8da;
$secondary-color: #92929d;
$sub-primary-color: #def2f8;
$danger-color: #ff5151;
$orange-color: #fe8257;

$borders-color: #f7f7f7;
$borders-width: 2px;
$borders-radius: 12px;
$element-min-height: 40px;

$items-padding: 10px;

// $table-header-background: #eef8fb;
$table-header-background: #def1f8;
$table-row-background: $card-background;
$table-row-hover-background: #f7fbfd;
$table-header-color: #42829a;
$table-header-font-size: 12px;
$table-header-font-weight: 500;
$table-header-line-height: 14px;
$table-header-padding: 14px;
$table-padding: 16px;
$table-border-radius: 12px;
$table-border-color: #f3f3f5;
$table-row-margin: 8px;

$pagination-color: #92929d;
$pagination-color-active: #57b7da;
$pagination-border-radius: 8px;
$pagination-element-width: 32px;
$pagination-element-height: 32px;

$transition-duration-short: 0.15s;
$transition-duration-medium: 0.3s;

$radio-card-bg: #ffffff;
$radio-card-color-active: #57b7da;
$radio-card-padding-m: 10px;
$radio-card-padding-l: 24px;
$radio-card-blur-radius: 4px;
