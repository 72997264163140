.loader {
  margin-bottom: 0;
}

.thumb {
  margin-top: 3px;
  min-width: 35px;
  border-radius: 50%;
}

.user {
  cursor: pointer;
}

.photo {
  border: #f7f7f7 2px solid;
}

@media screen and (max-width: 1024px) {
  .photo {
    width: 35px;
    min-width: 35px;
    height: 35px;
    margin-right: -8px;
  }

  .name,
  .phone {
    display: none;
  }
}
