.wrapper {
  overflow: auto;
}

.container {
  &:first-child {
    margin-top: 0;
  }
}

.block {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: #e7e8ea 1px solid;

  &:last-child {
    margin-bottom: 0;
  }

  &Title {
    margin-bottom: 8px;
  }
}

.field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  &Left {
    display: flex;
    align-items: center;
  }

  &Icon {
    width: 40px;
    display: flex;
    align-items: center;
  }

  &Title {
    font-weight: 500;
  }
}

.switch {
  flex: none;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    min-height: auto;
  }
}
