.dialog {
  width: 100%;
  max-width: 600px;
}

.preloader {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 18px;
}
