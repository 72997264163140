.categories {
  background: #fff;
  cursor: pointer;
  font-size: 12px;
  border: rgba(134, 138, 151, 0.2) 1px solid;
  border-radius: 12px;
  padding: 2px 16px 12px;
  white-space: nowrap;
  outline: none;
  min-width: 300px;
  max-width: 500px;
}

.category {
  display: block;
  padding: 15px 0 0 0;
  position: relative;

  .category {
    margin-left: 21px;

    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: -14px;
      background-color: rgba(134, 138, 151, 0.2);
    }

    &:before {
      width: 1px;
      bottom: 0;
    }

    &:after {
      width: 10px;
      height: 1px;
      top: 22px;
    }

    &:last-child {
      &:before {
        bottom: 6px;
      }
    }
  }
}

.categoryLink {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  color: #000;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}

.categoryIcon {
  display: inline-block;
  vertical-align: top;
  margin-right: 7px;
  margin-top: -1px;
  color: #42829a;
}
