.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding: 20px;
}

.text {
  max-width: 600px;
  font-size: 14px;
  font-weight: 500;
  margin: 24px 0;

  p {
    margin: 0;
  }
}
