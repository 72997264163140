.folder {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  position: relative;
  cursor: pointer;

  &:after {
    border-bottom: rgba(134, 138, 151, 0.1) 1px solid;
    content: '';
    width: calc(100% - 48px);
    height: 1px;
    position: absolute;
    bottom: 0;
  }

  &:not(.active):hover {
    background-color: rgba(222, 241, 248, 0.2);
  }

  &.active {
    background-color: rgba(222, 241, 248, 0.2);

    &:before {
      content: '';
      position: absolute;
      top: 12px;
      bottom: 12px;
      width: 0;
      left: 0;
      border: 2px solid #6eb6d6;
      border-radius: 0 4px 4px 0;
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  justify-content: center;
  overflow: hidden;

  strong {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .folder {
    padding: 16px;
  }
}
