.container {
  margin-top: 20px;
}

.uploader {
  width: 180px;
}

@media screen and (max-width: 1024px) {
  .uploader {
    margin: 0 auto;
  }
}
