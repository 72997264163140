.questions {
  padding-left: 80px;
  padding-right: 80px;
}

@media screen and (max-width: 1024px) {
  .questions {
    padding: 0;
  }
}
