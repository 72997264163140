.container {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.header {
  margin-bottom: 56px;
}

.title {
  text-align: center;
  font-size: 43px;
  margin-bottom: 0;
}

.description {
  margin-bottom: -24px;
  margin-top: 12px;
}

.form {
  margin-bottom: 48px;
}

.footer {
  margin-top: 24px;
  text-align: center;
}

.button {
  height: 50px;
}

@media screen and (max-width: 640px) {
  .container {
    max-width: 95%;
  }
}
