.table {
  transition: opacity 500ms ease;
  border-spacing: 0;
}

.loading {
  opacity: 0.4;
}

@media screen and (max-width: 1024px) {
  .table {
    white-space: normal;

    thead {
      display: none;
    }

    tbody {
      td {
        padding-left: 0;
        padding-right: 0;

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
}
