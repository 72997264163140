.text {
  margin-bottom: 12px;
  font-size: 14px;
}

.timer {
  margin: 12px 0;
  font-size: 12px;
}

.link {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}
