.barcode {
  width: 100% !important;
  background: #f7f7f7;
  padding: 5px;
  border-radius: 8px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.uploader {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .uploader {
    width: 150px;
    margin: 0 auto;
  }
}
