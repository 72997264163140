.channel {
  display: flex;
  padding: 10px;
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.provider {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 500;
}

.icon {
  margin-right: 5px;
}

.badge {
  top: 0;
  left: 5px;
}

.date {
  padding-left: 26px;
}
