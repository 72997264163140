.item {
  border-radius: 12px;
  border: rgba(87, 183, 218, 0.5) 1px dashed;
  padding: 20px;
  text-align: center;
  flex: 0 0 31%;
  margin: 10px;
}

.image {
  max-width: 75%;
  height: auto;
}

.title {
  color: #2e292b;
  font-weight: 500;
}

.description {
  color: #868a97;
  font-size: 13px;
  line-height: 16px;
  margin: 8px 0 12px;
}

@media screen and (max-width: 768px) {
  .item {
    flex: 0 0 100%;
    margin: 10px 0;
  }
}
