.modal {
  max-width: 600px;
}

.buttons {
  margin-top: 32px;
  text-align: right;
}

.button {
  margin-left: 16px;
}

.text {
  color: #6c707a;
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  text-align: center;
}

.icon {
  margin-bottom: 20px;
}
